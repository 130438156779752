// https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=YOUR_API_KEY

import axios from 'axios'

const mainUri = 'https://maps.googleapis.com/maps/api/geocode/json'
const ak = process.env.VUE_APP_GMAP_API_KEY

export function getAddress(latlng) {
  return new Promise((resolve, reject) => {
    axios({
      baseURL: mainUri,
      method: 'get',
      params: {
        latlng: `${latlng.lat},${latlng.lng}`,
        key: ak,
        language: 'th',
      },
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
