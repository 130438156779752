import http from './http'
import axios from 'axios'

const mainUri = '/api/v2/command'
const showUri = '/api/v2/checkpoint'

export function list(commandId, params) {
  return http({
    url: `${mainUri}/${commandId}/checkpoint`,
    method: 'get',
    params: params,
  })
}

export function listAll(params) {
  return http({
    url: `${showUri}All`,
    method: 'get',
    params: params,
  })
}

export function listExists() {
  return http({
    url: `${showUri}Exist`,
    method: 'get',
  })
}

export function listToday(params) {
  return http({
    url: `${showUri}Today`,
    method: 'get',
    params: params,
  })
}

export function store(commandId, data) {
  return http({
    url: `${mainUri}/${commandId}/checkpoint`,
    method: 'post',
    data: data,
  })
}

export function duplicate(commandId, checkpointId) {
  return http({
    url: `${mainUri}/${commandId}/checkpoint/${checkpointId}/duplicate`,
    method: 'post',
  })
}

export function update(commandId, data) {
  return http({
    url: `${mainUri}/${commandId}/checkpoint/${data.id}`,
    method: 'patch',
    data: data,
  })
}

export function toggleActive(id) {
  return http({
    url: `${showUri}/${id}/toggleActive`,
    method: 'patch',
  })
}

export function updateActive(id, data) {
  return http({
    url: `${showUri}/${id}/updateActive`,
    data: data,
    method: 'patch',
  })
}

export function show(id) {
  return http({
    url: `${showUri}/${id}`,
    method: 'get',
  })
}

export function activities(id) {
  return http({
    url: `${showUri}/${id}/activities`,
    method: 'get',
  })
}

export function destroy(commandId, id) {
  return http({
    url: `${mainUri}/${commandId}/checkpoint/${id}`,
    method: 'delete',
  })
}

export function multiDelete(data) {
  return http({
    url: `${showUri}Multidelete`,
    method: 'delete',
    data,
  })
}

export function types() {
  return http({
    url: `${showUri}Type`,
    method: 'get',
  })
}

export function nearby(params) {
  return http({
    url: `${showUri}Nearby`,
    method: 'get',
    params: params,
  })
}

export function havePast() {
  return http({
    url: `${showUri}HavePast`,
    method: 'get',
  })
}

export function clusters(ids) {
  return http({
    url: `${showUri}Clusterer`,
    method: 'get',
    params: { ids: ids },
  })
}

export function qrcode(id) {
  return http({
    url: `${showUri}Qrcode/${id}`,
    method: 'get',
  })
}

export function exportTodayExcel(params) {
  // axios.get(`https://tpcc.police.go.th/2021/api/v2/checkpointTodayExport`, {responseType: 'blob'})
  return http({
    url: `/api/v2/checkpointTodayExport`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}

export function exportAllExcel(params) {
  // axios.get(`https://tpcc.police.go.th/2021/api/v2/checkpointTodayExport`, {responseType: 'blob'})
  return http({
    url: `/api/v2/checkpointAllExport`,
    method: 'get',
    params: params
  }, {responseType: 'blob'})
  .then(res => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const a = document.createElement("a");
    a.href = url;
    const filename = `report.csv`;
    a.setAttribute('download', filename);
    a.click();
    a.remove();
  })
}