<template>
  <v-dialog v-model="dialog" persistent fullscreen>
    <v-snackbar :timeout="2000" v-model="showAlert" absolute centered :color="snackColor">
      {{ message }}
    </v-snackbar>
    <v-card :loading="loading">
      <v-card-title class="headline">
        <v-icon>
          mdi-traffic-cone
        </v-icon>
        ข้อมูลด่านตรวจ
        <span class="grey--text subtitle-2"> {{ type == 'edit' ? '(แก้ไข)' : '' }}</span>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="12">
            <cp-form ref="cpForm" :valid.sync="valid" :type="type" v-model="checkpoint_edit"></cp-form>
            <map-form
              ref="mapForm"
              :address.sync="checkpoint_edit.address"
              :lat.sync="checkpoint_edit.lat"
              :lng.sync="checkpoint_edit.lng"
              :road.sync="checkpoint_edit.road"
              :inbound.sync="checkpoint_edit.inbound"
              :marker-type="checkpoint_edit.checkpoint_type_id"
              :valid.sync="valid"
            ></map-form>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-btn text @click="closeDialog">{{ type == 'edit' ? 'ปิด' : 'ยกเลิก' }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="saveData" :disabled="!checkpoint_edit.can.update" :loading="loading">
          <v-icon>
            mdi-content-save
          </v-icon>
          บันทึกข้อมูล
        </v-btn>
      </v-card-actions>
      <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import cpForm from './partials/form'
import mapForm from './partials/gmapForm'
import { show, store, update } from '@/api/pcheckpoint'
export default {
  methods: {
    saveData() {
      if (this.$refs.cpForm.validate() && this.$refs.mapForm.validate()) {
        if (this.type == 'edit') {
          // do edit
          this.loading = true
          update(this.checkpoint_edit)
            .then(res => {
              // console.log(res)
              this.showMessage('แก้ไขข้อมูลเรียบร้อยแล้ว')
            })
            .catch(err => {
              if (err.response.status == 403) {
                this.$refs.confirm.open('Forbiden', 'จุดตรวจนี้มีการตั้งไปแล้ว ไม่สามารถแก้ไขได้', {
                  color: 'red',
                  noconfirm: true,
                })
              }
            })
            .finally(() => {
              this.loading = false
            })
          return
        }

        // do add new
        let data = this.checkpoint_edit
        this.loading = true
        store(data)
          .then(res => {
            this.nearbyCheckpoints = []
            this.checkpoint_edit = Object.assign({}, this.checkpoint)
            this.valid = true

            this.$emit('submit', res)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    async initCheckpoint(id, type) {
      if (type == 'edit') {
        this.checkpoint_edit = Object.assign({}, await show(id))
        this.type = 'edit'
        // set date and time that accept by vuetify
        // this.checkpoint_edit.times =
      } else {
        this.checkpoint_edit = Object.assign({}, this.checkpoint)
        this.type = 'add'
        this.officers = []
      }
    },

    closeDialog() {
      this.checkpoint_edit = Object.assign({}, this.checkpoint)
      this.nearbyCheckpoints = []
      this.valid = true
      this.$emit('cancelClick')
    },
    showMessage(msg, color) {
      if (color == undefined) color = 'primary'
      this.snackColor = color
      this.message = msg
      this.showAlert = true
    },
  },
  data() {
    return {
      type: 'add',
      valid: true,
      cmdId: '',
      loading: false,
      checkpoint_edit: {
        can: {
          update: true,
          delete: true,
          open: true,
        },
      },
      message: '',
      snackColor: 'primary',
      showAlert: false,
      checkpoint: {
        id: '',
        name: '',
        address: '',
        road: '',
        lat: null,
        lng: null,
        checkpoint_types: [],
        inbound: 1,
        remark: null,
        times: '',
        can: {
          update: true,
          delete: true,
          open: true,
        },
      },
    }
  },
  created() {},
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    allowDates: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    cpForm,
    mapForm,
  },
  watch: {
    '$route.params.id'(v) {
      this.cmdId = v
    },
  },
}
</script>

<style></style>
