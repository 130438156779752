import http from './http'

const mainUri = '/api/v2/command'
const showUri = '/api/v2/pcheckpoint'

export function list(params) {
  return http({
    url: `${showUri}`,
    method: 'get',
    params: params,
  })
}

export function listExists() {
  return http({
    url: `${showUri}Exist`,
    method: 'get',
  })
}

export function store(data) {
  return http({
    url: `${showUri}`,
    method: 'post',
    data: data,
  })
}

export function update(data) {
  return http({
    url: `${showUri}/${data.id}`,
    method: 'patch',
    data: data,
  })
}

export function toggleActive(id) {
  return http({
    url: `${showUri}/${id}/toggleActive`,
    method: 'patch',
  })
}

export function show(id) {
  return http({
    url: `${showUri}/${id}`,
    method: 'get',
  })
}

export function activities(id) {
  return http({
    url: `${showUri}/${id}/activities`,
    method: 'get',
  })
}

export function destroy(id) {
  return http({
    url: `${showUri}/${id}`,
    method: 'delete',
  })
}

export function qrcode(id) {
  return http({
    url: `${showUri}Qrcode/${id}`,
    method: 'get',
  })
}
