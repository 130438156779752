<template>
  <v-form v-model="formValid" ref="form" lazy-validation>
    <!-- name -->
    <v-row class="pb-0">
      <v-col>
        <v-select
          label="ประเภท (เลือกได้หลายแบบ)"
          v-model="checkpoint.checkpoint_types"
          :items="CheckpointTypes"
          multiple
          :rules="reqRule"
          required
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="ชื่อด่านตรวจ"
          hint="ด่านตรวจถาวร..."
          v-model="checkpoint.name"
          :rules="reqRule"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pb-0"> </v-row>
  </v-form>
</template>

<script>
import { selectBox, types as officerType } from '@/api/officer'
import { types as cpType } from '@/api/checkpoint'
import { list as listGroup, show as showGroup } from '@/api/officergroup'
export default {
  // TODO change this all method
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    // ใช้ค้นหาชื่อ จนท.
    officerInfo(id) {
      const officer = this.officers.find(o => o.value == id)
      return officer === undefined ? '' : officer.text
    },
    removeOfficer(i) {
      this.officerSelected.splice(i, 1)
      this.officerSelectedObject.splice(i, 1)
    },

    getOfficerType() {
      officerType().then(res => {
        this.officerTypes = res.map(o => ({ value: o.id, text: o.name }))
      })
    },
    getCheckpointType() {
      cpType().then(res => {
        this.CheckpointTypes = res.map(o => ({
          value: o.id,
          text: o.name,
        }))
      })
    },
  },
  data: () => ({
    reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
    hasChief: true,
    reqArrRule: [v => v.length != 0 || 'จำเป็นต้องกรอกข้อมูล'],
    CheckpointTypes: [],
    groups: [],
    groupsModel: [],
    groupSelected: null,
    officers: [],
    officerSelectedData: [],
    officerTypes: [],
    datemenu: false,
    times: '',
    timeRules: [
      v =>
        /^((?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?)-(?:\s?)(?:[01]\d:[0-5][0-9]|2[0-3]:[0-5][0-9])(?:\s?,\s?)?)+$/.test(
          v
        ) || 'กรอกเวลาให้ถูกต้อง',
    ],
  }),
  computed: {
    dateFormat() {
      if (Array.isArray(this.checkpoint.start_at)) {
        return this.checkpoint.start_at
          .map(d =>
            this.moment(d)
              .add(543, 'year')
              .format('D MMM')
          )
          .join(',')
      }
      return (
        this.moment(this.checkpoint.start_at)
          .add(543, 'year')
          .format('D MMMYYYY') || null
      )
    },
    formValid: {
      get() {
        return this.valid
      },
      set(v) {
        this.$emit('update:valid', v)
      },
    },
    dates: {
      get() {
        return [this.checkpoint.start_at, this.checkpoint.end_at]
      },
      set(v) {
        this.checkpoint.start_at = v[0]
        this.checkpoint.end_at = v[1]
      },
    },
    // ใช้สำหรับตาราง
    officerSelectedObject: {
      get() {
        return this.officersObj
      },
      set(v) {
        this.$emit('update:officersObj', v)
      },
    },
    checkpoint: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    min() {
      if (this.allowDates.length == 0) return undefined
      return this.allowDates[0]
    },
    max() {
      if (this.allowDates.length == 0) return undefined
      return this.allowDates[1]
    },
  },
  watch: {
    checkpoint() {
      this.resetValidation()
    },
    groupSelected(v) {
      if (v) {
        showGroup(v).then(res => {
          this.officerSelected = res.officers.map(o => o.id)
        })
      }
    },
  },
  async created() {
    const officers = await selectBox()
    const g = await listGroup()
    this.groups = g.map(g => ({
      text: g.name,
      value: g.id,
    }))
    if (officers) this.officers = officers
  },
  mounted() {
    this.getOfficerType()
    this.getCheckpointType()
  },
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'add',
    },
  },
}
</script>

<style></style>
