import http from './http'

const mainUri = '/api/v2/officer'

export function list(params) {
  return http({
    url: `${mainUri}`,
    method: 'get',
    params: params,
  })
}

export function selectBox() {
  return http({
    url: `${mainUri}SelectBox`,
    method: 'get',
  })
}

export function store(data) {
  return http({
    url: `${mainUri}`,
    method: 'post',
    data: data,
  })
}

export function update(data) {
  return http({
    url: `${mainUri}/${data.id}`,
    method: 'patch',
    data: data,
  })
}

export function polis() {
  return http({
    url: `${mainUri}Polis`,
    method: 'post',
  })
}

export function polisInfo(id) {
  return http({
    url: `${mainUri}Polis/${id}`,
    method: 'post',
  })
}

export function destroy(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'delete',
  })
}

export function admin() {
  return http({
    url: `${mainUri}Admin`,
    method: 'get',
  })
}

export function byCheckpoint(cid) {
  return http({
    url: `${mainUri}ByCheckpoint/${cid}`,
    method: 'get',
  })
}

export function show(id) {
  return http({
    url: `${mainUri}/${id}`,
    method: 'get',
  })
}

export function types() {
  return http({
    url: `${mainUri}Type`,
    method: 'get',
  })
}

export function title() {
  return http({
    url: `${mainUri}Title`,
    method: 'get',
  })
}

export function level() {
  return http({
    url: `${mainUri}Level`,
    method: 'get',
  })
}
