<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-sign-caution
          </v-icon>
          ด่านตรวจถาวร
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex">
          <v-btn color="indigo darken-2" dark class="mr-1" @click="newOfficer">
            <v-icon small>
              mdi-plus-box
            </v-icon>
            เพิ่มด่านตรวจ
          </v-btn>
          <v-spacer></v-spacer>
        </div>
      </v-card-text>
      <pcp-table
        :keyword="keyword"
        :type="type"
        :show-over="showOver"
        ref="otable"
        @row-dbl-click="editOfficer"
        @remove-user="deleteOfficer"
      ></pcp-table>
    </v-card>

    <!-- dialog -->

    <dialog-form
      ref="cpDialog"
      v-model="userDialog"
      :type="dataActionType"
      @cancelClick="cancelDialog"
      @submit="onSubmit"
    ></dialog-form>

    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    <pdf-dialog ref="pdf"></pdf-dialog>
  </v-container>
</template>

<script>
import pcpTable from './components/pcpTable'
import dialogForm from './dialogForm'
import pdfDialog from '@/components/pdfDialog'

import { destroy } from '@/api/pcheckpoint'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.cpDialog.initCheckpoint(null, 'add')
      this.userDialog = true
    },
    onSubmit(v) {
      this.dataActionType = 'add'
      this.$refs.otable.getList()
      this.userDialog = false
    },
    cancelDialog() {
      this.dataActionType = 'add'
      this.$refs.otable.getList()
      this.userDialog = false
    },
    editOfficer(id) {
      this.$refs.cpDialog.initCheckpoint(id, 'edit')
      this.userDialog = true
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.userDialog = false
      this.$refs.otable.getList()
    },
  },
  data() {
    return {
      keyword: '',
      userDialog: false,
      dataActionType: 'add',
      type: 'name',
      types: [{ text: 'ชื่อด่านตรวจ', value: 'name' }],
      modify: 'add',
      loading: false,
      showOver: false,
    }
  },
  components: {
    pcpTable,
    pdfDialog,
    dialogForm,
  },
}
</script>

<style></style>
